<template>
  <div class="ucenter css-helper">
    <div class="content content-scale">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="top">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>会员中心</el-breadcrumb-item>
        <el-breadcrumb-item class="current">{{
          name
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="outer flex flex-justify-between">
        <div class="left-outer flex-item">
          <div
            v-for="(item, index) in items"
            :key="index"
            class="left flex"

            :class="{ active: currentItem === item.value }"
            @click="change(item)"
          >
            <div class="flex-item flex-item-center">
              <img
                :src="
                  currentItem === item.value ? item.activeImage : item.image
                "
                class="img"
              />
            </div>

            <div class="text flex-item flex-item-center">{{ item.name }}</div>
            <div class="flex-item flex-item-center flex-1 img2-outer">
              <img
                :src="currentItem === item.value ? jiantoua : jiantou"
                class="img2"
              />
            </div>
          </div>
          <div class="left flex" @click="logout">
            <div class="flex-item flex-item-center">
              <img src="@/assets/ucenter/user.png" class="img" />
            </div>
            <div class="text flex-item flex-item-center">退出登录</div>
            <div class="flex-item flex-item-center flex-1 img2-outer">
              <img src="" class="img2" />
            </div>
          </div>
        </div>
      
        <div class="right flex-item">
          <orderdetails></orderdetails>
        </div>
      </div>
    </div>

    <footer-com id="foot"></footer-com>
  </div>
</template>

<script>
import footerCom from "@/components/footer.vue";
import Firset from "@/components/ucenter/management.vue"
import Orders from './myActivityDetails'
import orderdetails from '../../components/ucenter/activityOrderDetail'
import Like from '@/components/ucenter/like.vue'
import safe from '@/components/ucenter/safe.vue'
import Bus from "../../../public/bus"

export default {
  data: function () {
    return {
      flag:true,
      currentItem: 'myActivity',
      currentName: "我的预约",
      jiantou: require("@/assets/ucenter/jiantou.png"),
      jiantoua: require("@/assets/ucenter/jiantoua.png"),
      items: [
        {
          name: "账户管理",
          value: "management",
          address: "/ucenter",
          image: require("@/assets/ucenter/user.png"),
          activeImage: require("@/assets/ucenter/usera.png"),
        },
        {
          name: "我的预约",
          value: "order",
          address: "/order",
          image: require("@/assets/ucenter/order.png"),
          activeImage: require("@/assets/ucenter/ordera.png"),
        },
        {
          name: "我的活动",
          value: "myActivity",
          address: "/myActivity",
          image: require("@/assets/ucenter/order.png"),
          activeImage: require("@/assets/ucenter/ordera.png"),
        },
        {
          name: "我的收藏",
          value: "like",
          address: "/like",
          image: require("@/assets/ucenter/like.png"),
          activeImage: require("@/assets/ucenter/likea.png"),
        },
        {
          name: "账号安全",
          value: "safe",
          address: "/safe",
          image: require("@/assets/ucenter/safe.png"),
          activeImage: require("@/assets/ucenter/safea.png"),
        },
      ],
      Appointmentid: "",
      name:'我的活动',
      id:this.$route.query.id
    };
  },
  components: {
    footerCom,
    Firset,
    orderdetails,
    Like,
    safe
  },
  mounted(){
      document.title = '个人中心'
      
  },
  watch: {
    $route: function () {
      this.currentItem = this.$route.meta.itemName;
    },
  },
  methods: {
    hot_List(){
      this.$store.state.flag = true
      console.log(this.$store.flag,'我的侯东')
    },
    change(i) {
      this.currentItem = i.value;
      // this.$router.push(i.address);
      this.currentName = i.name;
      this.name = i.name
      // if(this.currentName=='我的活动'){
      //   console.log(this.currentName)
      //     this.$store.state.flag = true
      // } 
      if(this.currentName=='我的预约'){
        console.log(this.currentName)
        this.$router.push('/order')
}else{
      this.$router.push(i.address)

      }
    },
    orderDetail(name, id) {
      this.Appointmentid = id;
      if (this.currentItem !== "order") {
        return;
      }
      this.currentComponent = name;
    },
    logout() {
      localStorage.removeItem("token");
      this.showPopupMessage("退出登陆成功", false);
      setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
  },
};
</script>

<style  lang="scss">
.ucenter {
  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
    color: #bd955d !important;
  }
  .el-breadcrumb__item:first-child .el-breadcrumb__inner:hover {
    color: #bd955d !important;
  }
}
</style>


<style lang="scss" scoped>
.ucenter {
  padding-top: 26px;
  background: rgba(225, 230, 240, 0.2);
  font-family: "SourceHanSansCN-Regular";

  .content {
    padding-bottom: 60px;
    margin: 0 auto;
    max-width: 1400px;
    // width: 72.9%;
    min-height: 100vh;
    box-sizing: border-box;
    .top {
      max-width: 1400px;
      margin: 0 auto 46px;
      font-size: 15px;
    }
    .outer {
      margin: 0 auto;
      display: flex;
      .left-outer {
        // margin-right: 60px;
        // max-width: 446px;
        width: 31.86%;
        .left:first-child {
          border-top: 3px solid rgba(189, 149, 93, 1);
        }
        .left:last-child {
          border-radius: 0 0 20px 20px;
          border-bottom: none;
        }
        .active {
          background-color: rgba(239, 242, 247, 1) !important;
          color: rgba(176, 103, 41, 1);
        }
        .left {
          font-size: 18px;
          height: 74px;
          line-height: 74px;
          background-color: #fff;
          padding-left: 40px;
          cursor: pointer;
          color: #333333;
          border-bottom: 1px solid #E1E6F0;
          &:hover{
              color: #000;
            }
          .img {
            width: 16px;
            margin-right: 20px;
            transform: translateY(2px);
            border: none;
          }
          .img2-outer {
            text-align: right;
            padding-right: 30px;
          }
          .img2 {
            width: 8px;
          }
          .text {
            font-size: 18px;
            display: inline-block;
            // width: 370px;
            margin-left: 14px;
            height: 74px;
            
          }
        }
      }

      .right {
        text-align: left;
        // width: 852px;
        width: 65.15%;
        background-color: #fff;
        border-radius: 0 0 20px 20px;
        box-shadow: 0px 23px 35px 0px rgba(0, 0, 0, 0.07);
        border-top: 3px solid rgba(189, 149, 93, 1);
        padding: 20px 30px;
        box-sizing: border-box;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .content-scale {
    transform: scale(0.9);
    transform-origin: top center;
  }
  .ucenter {
    background: rgba(225, 230, 240, 0.2);
    font-family: "SourceHanSansCN-Regular";
  }
}
</style>

