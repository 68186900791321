<template>
  <div class="order-detail css-helper">
    <div class="top">
      <div class="order-title">预约详情</div>
      <div class="item">
        <div class="left">预约编号</div>
        <div>{{ detailsobj.yy_code }}</div>
      </div>
      <div class="item">
        <div class="left">手机号码</div>
        <div>{{ detailsobj.phone }}</div>
      </div>
      <div class="item">
        <div class="left">参观时间</div>
        <div>{{ detailsobj.day_time_stamp }}</div>
      </div>
      <div class="item">
        <div class="left">姓名</div>
        <div>{{ detailsobj.name }}</div>
      </div>
      <div class="item">
        <div class="left">身份证号</div>
        <div>{{ detailsobj.card_num }}</div>
      </div>
      <div class="item">
        <div class="left">预约时间</div>
        <div>{{ detailsobj.create_time }}</div>
      </div>
      <div class="cancel" @click="btn()" v-show="detailsobj.yy_status == 1">
        取消预约
      </div>
      <div class="cancel disable" v-show="detailsobj.yy_status == 2">
        预约已取消
      </div>
    </div>
    <div class="flex center">
      <div class="flex-item flex-item-center image image1"></div>
      <div class="flex-item flex-1 flex-item-center notice-title">参观须知</div>
    </div>
    <div class="bottom">
      <div class="item flex">
        <div class="flex-item image image2"></div>
        <div class="flex-item flex-1">
          开放方式为预约参观。每位观众均须提前在顺义数字博物馆网站进行实名预约.
        </div>
      </div>
      <div class="item flex">
        <div class="flex-item image image2"></div>
        <div class="flex-item flex-1">
          观众须在顺义博物馆西门入口处完成①体温检测、②北京市（健康宝）健康码检查、
          ③行程码查验④携带本人二代身份证验证预约信息并领取门票，方可进入馆区。<span
            class="text-red"
            >北京市（健康宝）健康码显示异常、行程码显示异常、未预约这四种情况中任何一种情况的观众，</span
          >暂不得进入馆区。外籍人员预约到馆参观需携带护照，并出示“Health
          Kit（健康宝）”。
        </div>
      </div>
      <div class="item flex">
        <div class="flex-item image image2"></div>
        <div class="flex-item flex-1">
          观众进入馆区后，西门入馆参观。参观须全程佩戴口罩，未戴口罩谢绝参观。观众在排队、安检、参观、
          乘扶梯、休息时均须自觉与其他观众<span class="text-red"
            >保持1.5米间距</span
          >，避免聚集。
        </div>
      </div>
      <div class="item flex">
        <div class="flex-item image image2"></div>
        <div class="flex-item flex-1">
          若您参观时出现发热或身体不适等情况，请及时联系就近工作人员。
        </div>
      </div>
      <div class="item flex">
        <div class="flex-item image image2"></div>
        <div class="flex-item flex-1">
          顺义博物馆将严格落实各项疫情防控措施，确保有序安全的开放环境。最终战胜疫情是我们与您的共同追求，
          在此感谢各位亲爱的观众，感谢您对抗疫的支持和对顺义博物馆服务工作的配合。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      detailsobj: {},
      id: '',
    };
  },
  // props: ["id"],
  mounted() {
    this.id = this.$route.query.id
    this.goData();
  },
  methods: {
    goData() {
      let query = {
        source: "pc",
        id: this.id,
      };
      this.$post("/orderDetail", query).then((res) => {
        this.detailsobj = res.data;
        this.detailsobj.day_time_stamp = moment(
          1000 * parseInt(this.detailsobj.day_time_stamp)
        ).format("YYYY-MM-DD");
        this.detailsobj.create_time = moment(
          1000 * parseInt(this.detailsobj.create_time)
        ).format("YYYY-MM-DD");
      });
    },
    btn() {
      let query = {
        source: "pc",
        id: this.id,
      };
      // let obj = {
      //   name: "我的预约",
      //   value: "order",
      // };
      this.$post("/orderCancle", query).then((res) => {
        this.popupMsg = res.msg;
        this.showPopupMsg();
        this.toTop();
        // this.$emit("change", obj);
        setTimeout(() => this.goData(), 2000);
      });
    },
    showPopupMsg() {
      this.showPopupMessage(this.popupMsg, this.popupCode === 1);
    },
    toTop() {
      let el = document.querySelector("#frame_content_view");
      if (el) {
        el.scrollTop = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.order-detail {
  padding: 30px 0 40px;
  .top {
    background: url("../../assets/ucenter/order-detail-bg.png") top center
      no-repeat;
    background-size: 818px 506px;
    padding: 40px 80px 80px;
    color: #333333;
    position: relative;
    .order-title {
      font-size: 24px;
      text-align: center;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .item {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      margin: 0 48px;
      font-size: 16px;
      height: 54x;
      line-height: 54px;
      border-bottom: 1px solid rgba(223, 222, 217, 1);

      .left {
        color: rgba(136, 136, 136, 1);
      }
    }
    .item:nth-child(7) {
      border-bottom: none !important;
    }
    .cancel {
      color: #fff;
      background-color: #bd955d;
      border-radius: 10px;
      font-weight: bolder;
      text-align: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 216px;
      line-height: 48px;
      top: 430px;
      font-size: 18px;
      &.disable {
        background-color: #d6d4d4;
        color: #999999;
      }
    }
  }
  .center {
    margin: 35px 0 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e1e6f0;
    .image1 {
      width: 30px;
      height: 30px;
      background-image: url("../../assets/ucenter/notice.png");
      margin-right: 5px;
    }
    .notice-title {
      font-size: 20px;
      color: #b26c30;
    }
  }
  .bottom {
    font-size: 14px;
    color: #333333;
    .item {
      margin-bottom: 20px;
      line-height: 26px;
      .text-red {
        color: #da0112;
      }
      .image2 {
        width: 16px;
        height: 16px;
        background-image: url("../../assets/ucenter/notice2.png");
        margin-right: 10px;
        margin-top: 5px;
      }
    }
  }
}
</style>
